import { computed, reactive, toRefs } from 'vue'

const layoutConfig = reactive({
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    menuTheme: 'colorScheme',
    colorScheme: 'light',
    theme: 'indigo',
    scale: 14
})

const layoutState = reactive({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    sidebarActive: false,
    anchored: false,
    overlaySubmenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    activeMenuItem: null
})

const mobileBreakpoint = 768

export function useLayout() {
    const setScale = scale => {
        layoutConfig.scale = scale
    }

    const setActiveMenuItem = item => {
        layoutState.activeMenuItem = item.value || item
    }

    const onMenuToggle = () => {
        if (layoutConfig.menuMode === 'overlay') {
            layoutState.overlayMenuActive = !layoutState.overlayMenuActive
        }

        if (window.innerWidth > mobileBreakpoint) {
            layoutState.staticMenuDesktopInactive =
                !layoutState.staticMenuDesktopInactive
        } else {
            layoutState.staticMenuMobileActive =
                !layoutState.staticMenuMobileActive
        }
    }

    const onProfileSidebarToggle = () => {
        layoutState.profileSidebarVisible = !layoutState.profileSidebarVisible
    }

    const onConfigSidebarToggle = () => {
        layoutState.configSidebarVisible = !layoutState.configSidebarVisible
    }

    const isSidebarActive = computed(
        () =>
            layoutState.overlayMenuActive ||
            layoutState.staticMenuMobileActive ||
            layoutState.overlaySubmenuActive
    )

    const isDarkTheme = computed(() => layoutConfig.darkTheme)

    const isDesktop = computed(() => window.innerWidth > mobileBreakpoint)

    const isSlim = computed(() => layoutConfig.menuMode === 'slim')
    const isSlimPlus = computed(() => layoutConfig.menuMode === 'slim-plus')

    const isHorizontal = computed(() => layoutConfig.menuMode === 'horizontal')

    return {
        layoutConfig: toRefs(layoutConfig),
        layoutState: toRefs(layoutState),
        setScale,
        onMenuToggle,
        isSidebarActive,
        isDarkTheme,
        setActiveMenuItem,
        onProfileSidebarToggle,
        onConfigSidebarToggle,
        isSlim,
        isSlimPlus,
        isHorizontal,
        isDesktop
    }
}
