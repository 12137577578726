<template>
    <div class="flex h-full flex-col">
        <div
            v-if="!isLightbox && !isKiosk && !isPrintMode"
            class="min-h-[2.5rem]"
        />

        <nuxt-link
            v-if="!isLightbox && !isKiosk"
            class="fixed left-0 right-0 top-0 z-30 flex h-10 items-center justify-center bg-primary-500 text-lg font-medium uppercase text-white hover:underline"
            :class="isPrintMode ? 'hidden' : ''"
            :to="activeFacility.organization.topBarLink"
        >
            {{ activeFacility.organization.topBarText }}
        </nuxt-link>

        <app-sidebar
            ref="sidebarRef"
            :class="[
                'Sidebar-Shadow fixed left-0 z-20 flex h-[100vh] w-[18rem] -translate-x-full flex-col border-r bg-white !shadow-none transition-transform md:transition',
                sidebarDisabled ? '' : 'md:translate-x-0',
                mobileSidebarEnabled ? 'translate-x-0' : '',
                isLightbox || isKiosk ? '' : 'top-10',
                isPrintMode ? 'hidden' : ''
            ]"
        />

        <div
            :class="[
                'ml-0 flex flex-1 flex-col p-4 md:p-8 md:transition-[margin]',
                sidebarDisabled || isPrintMode ? '' : 'md:ml-[18rem]',
                isSidebarActive.value ? 'overflow-hidden' : ''
            ]"
        >
            <app-topbar
                ref="topbarRef"
                class="3xl:mx-auto 3xl:w-[1504px]"
                :class="isPrintMode ? 'hidden' : ''"
            />

            <div
                class="3xl:mx-auto 3xl:w-[1504px]"
                :class="isPrintMode ? '' : 'mt-8'"
            >
                <slot />
            </div>

            <div class="flex-1" />

            <powered-by-footer class="-mx-4 -mb-4 mt-12 md:-mx-8 md:-mb-8" />
        </div>

        <div
            :class="[
                'fixed left-0 top-0 z-10 h-[100vh] w-full bg-black/40',
                mobileSidebarEnabled ? 'block md:hidden' : 'hidden'
            ]"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import AppTopbar from './AppTopbar.vue'
import AppSidebar from './AppSidebar.vue'
import { useLayout } from './composables/layout'
import { useViewer } from '~/composables/use-login'
import { useSetSelectedCustomer } from '~/components/Layouts/ProfileLayout/composables/use-selected-customer'
import { useRouter } from 'vue-router'
import { useRouteLink } from '~/composables/routing'
import PoweredByFooter from '~/components/PoweredByFooter.vue'
import { useLayoutMode } from '~/composables/use-layout-mode'
import { useActiveFacility } from '~/composables/use-active-facility'

const { layoutState, isSidebarActive } = useLayout()

const activeFacility = useActiveFacility()
const { isLightbox, isKiosk, isPrintMode } = useLayoutMode()

const outsideClickListener = ref(null)
const sidebarRef = ref(null)
const topbarRef = ref(null)

watch(isSidebarActive, newVal => {
    if (newVal) {
        bindOutsideClickListener()
    } else {
        unbindOutsideClickListener()
    }
})

onBeforeUnmount(() => {
    unbindOutsideClickListener()
})

const sidebarDisabled = computed(() => {
    return layoutState.staticMenuDesktopInactive.value
})

const mobileSidebarEnabled = computed(() => {
    return layoutState.staticMenuMobileActive.value
})

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = event => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive.value = false
                layoutState.overlaySubmenuActive.value = false
                layoutState.staticMenuMobileActive.value = false
                layoutState.menuHoverActive.value = false
            }
        }
        document.addEventListener('click', outsideClickListener.value)
    }
}
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener)
        outsideClickListener.value = null
    }
}
const isOutsideClicked = event => {
    const sidebarEl = sidebarRef?.value.$el
    const topbarEl = topbarRef?.value.$el.querySelector('.js-TopBarMenuButton')

    return !(
        sidebarEl.isSameNode(event.target) ||
        sidebarEl.contains(event.target) ||
        topbarEl.isSameNode(event.target) ||
        topbarEl.contains(event.target)
    )
}

const router = useRouter()
const viewer = useViewer()
const { orgHomeLink } = useRouteLink()
if (viewer.value === undefined) {
    // TODO: Prompt for login instead of redirecting
    router.push(orgHomeLink())
}

const setSelectedCustomer = useSetSelectedCustomer()
setSelectedCustomer({
    id: viewer.value.customerId,
    commaName: viewer.value.name,
    firstName: viewer.value.firstName
})
</script>

<style>
.Sidebar-Shadow {
    box-shadow: 0 4px 50px #d9ddfc;
}
</style>
