<template>
    <li
        ref="menuItemRef"
        :class="{
            'layout-root-menuitem': root,
            'active-menuitem': isActiveMenu
        }"
    >
        <div
            v-if="root && item.visible !== false"
            class="px-8 py-4 text-sm font-medium uppercase tracking-widest"
        >
            {{ item.label }}
        </div>

        <a
            v-if="(!item.to || item.items) && item.visible !== false"
            v-show="!root"
            v-tooltip.hover="
                isSlim && root && !isActiveMenu ? item.label : null
            "
            :href="item.url"
            :class="[
                'relative flex cursor-pointer items-center px-8 py-3 text-gray-600 outline-none transition-colors transition-shadow',
                item.class
            ]"
            :target="item.target"
            tabindex="0"
            @click="itemClick($event, item, index)"
            @mouseenter="onMouseEnter"
        >
            <i
                class="mr-2"
                :class="item.icon"
            />
            <span>{{ item.label }}</span>
            <i
                v-if="item.items"
                class="pi pi-fw pi-angle-down layout-submenu-toggler"
            />
        </a>

        <nuxt-link
            v-if="item.to && !item.items && item.visible !== false"
            v-show="!root"
            v-tooltip.hover="
                (isSlim || isSlimPlus) && root && !isActiveMenu
                    ? item.label
                    : null
            "
            :class="[
                'relative flex cursor-pointer items-center px-8 py-3 text-gray-600 outline-none transition-colors transition-shadow hover:bg-gray-100',
                item.class,
                { 'font-extrabold': checkActiveRoute(item) }
            ]"
            tabindex="0"
            :to="item.to"
            @click="itemClick($event, item, index)"
            @mouseenter="onMouseEnter"
        >
            <i
                class="mr-2"
                :class="item.icon"
            />
            <span>{{ item.label }}</span>
            <i
                v-if="item.items"
                class="pi pi-fw pi-angle-down layout-submenu-toggler"
            />
        </nuxt-link>

        <ul
            v-if="item.items && item.visible !== false"
            ref="subMenuRef"
            :class="{ 'layout-root-submenulist': root }"
        >
            <app-menu-item
                v-for="(child, i) in item.items"
                :key="child"
                :index="i"
                :item="child"
                :parent-item-key="itemKey"
                :root="false"
            />
        </ul>
    </li>
</template>

<script setup>
import { ref, onBeforeMount, watch, nextTick } from 'vue'
import { useLayout } from './composables/layout'
import { DomHandler } from 'primevue/utils'
import { useRoute } from '#app'

const route = useRoute()

const {
    layoutConfig,
    layoutState,
    setActiveMenuItem,
    onMenuToggle,
    isHorizontal,
    isSlim,
    isSlimPlus,
    isDesktop
} = useLayout()

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    },
    index: {
        type: Number,
        default: 0
    },
    root: {
        type: Boolean,
        default: true
    },
    parentItemKey: {
        type: String,
        default: null
    }
})

const isActiveMenu = ref(false)
const itemKey = ref(null)
const subMenuRef = ref(null)
const menuItemRef = ref(null)

onBeforeMount(() => {
    itemKey.value = props.parentItemKey
        ? props.parentItemKey + '-' + props.index
        : String(props.index)

    const activeItem = layoutState.activeMenuItem.value

    isActiveMenu.value =
        activeItem === itemKey.value || activeItem
            ? activeItem.startsWith(itemKey.value + '-')
            : false
})

watch(
    () => isActiveMenu.value,
    () => {
        const rootIndex = props.root
            ? props.index
            : parseInt(`${props.parentItemKey}`[0], 10)
        const overlay = document.querySelectorAll('.layout-root-submenulist')[
            rootIndex
        ]
        const target = document.querySelectorAll('.layout-root-menuitem')[
            rootIndex
        ]

        if (
            (isSlim.value || isSlimPlus.value || isHorizontal.value) &&
            isDesktop
        ) {
            nextTick(() => {
                calculatePosition(overlay, target)
            })
        }
    }
)

watch(
    () => layoutState.activeMenuItem.value,
    newVal => {
        isActiveMenu.value =
            newVal === itemKey.value || newVal.startsWith(itemKey.value + '-')
    }
)

watch(
    () => layoutConfig.menuMode.value,
    () => {
        isActiveMenu.value = false
    }
)

watch(
    () => layoutState.overlaySubmenuActive.value,
    newValue => {
        if (!newValue) {
            isActiveMenu.value = false
        }
    }
)
watch(
    () => route.path,
    newPath => {
        if (
            !(isSlim.value || isSlimPlus.value || isHorizontal.value) &&
            props.item.to &&
            props.item.to === newPath
        ) {
            setActiveMenuItem(itemKey)
        } else if (isSlim.value || isSlimPlus.value || isHorizontal.value) {
            isActiveMenu.value = false
        }
    }
)
const itemClick = async (event, item) => {
    if (item.disabled) {
        event.preventDefault()
        return
    }

    const { overlayMenuActive, staticMenuMobileActive } = layoutState

    if (
        (item.to || item.url) &&
        (staticMenuMobileActive.value || overlayMenuActive.value)
    ) {
        onMenuToggle()
    }

    if (item.command) {
        item.command({ originalEvent: event, item: item })
    }

    if (item.items) {
        if (
            props.root &&
            isActiveMenu.value &&
            (isSlim.value || isSlimPlus.value || isHorizontal.value)
        ) {
            layoutState.overlaySubmenuActive.value = false
            layoutState.menuHoverActive.value = false

            return
        }

        setActiveMenuItem(isActiveMenu.value ? props.parentItemKey : itemKey)

        if (
            props.root &&
            !isActiveMenu.value &&
            (isSlim.value || isSlimPlus.value || isHorizontal.value)
        ) {
            layoutState.overlaySubmenuActive.value = true
            layoutState.menuHoverActive.value = true
            isActiveMenu.value = true

            removeAllTooltips()
        }
    } else {
        if (!isDesktop) {
            layoutState.staticMenuMobileActive.value =
                !layoutState.staticMenuMobileActive.value
        }

        if (isSlim.value || isSlimPlus.value || isHorizontal.value) {
            layoutState.overlaySubmenuActive.value = false
            layoutState.menuHoverActive.value = false

            return
        }

        setActiveMenuItem(itemKey)
    }
}

const onMouseEnter = () => {
    if (
        props.root &&
        (isSlim.value || isSlimPlus.value || isHorizontal.value) &&
        isDesktop
    ) {
        if (!isActiveMenu.value && layoutState.menuHoverActive.value) {
            setActiveMenuItem(itemKey)
        }
    }
}
const removeAllTooltips = () => {
    const tooltips = document.querySelectorAll('.p-tooltip')
    tooltips.forEach(tooltip => {
        tooltip.remove()
    })
}
const calculatePosition = (overlay, target) => {
    if (overlay) {
        const { left, top } = target.getBoundingClientRect()
        const { width: vWidth, height: vHeight } = DomHandler.getViewport()
        const [oWidth, oHeight] = [overlay.offsetWidth, overlay.offsetHeight]
        const scrollbarWidth = DomHandler.calculateScrollbarWidth()

        // reset
        overlay.style.top = overlay.style.left = ''

        if (isHorizontal.value) {
            const width = left + oWidth + scrollbarWidth
            overlay.style.left =
                vWidth < width ? `${left - (width - vWidth)}px` : `${left}px`
        } else if (isSlim.value || isSlimPlus.value) {
            const height = top + oHeight
            overlay.style.top =
                vHeight < height ? `${top - (height - vHeight)}px` : `${top}px`
        }
    }
}

const checkActiveRoute = item => {
    return route.path === item.to
}
</script>
